#secondary-wrapper {
    width: 100%;
    display: flex;
    text-align: center;
    margin: 0 auto;
    text-align: center;
    background-color: var(--heighlight-font);
    border-radius: 40px;
}

#secondary-wrapper:hover {
    background-color: transparent !important;
}

.secondary-wrapper-icon {
    margin-right: 5px;
}

#secondary-wrapper a {
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    padding: 11px 15px;
    width: 160px;
    overflow: hidden;
}

#secondary-wrapper a span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

#secondary-wrapper a:hover span {
    color: white;
}

#secondary-wrapper a::after,
#secondary-wrapper a::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

#secondary-wrapper a::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

#secondary-wrapper a:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

#secondary-wrapper a::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

#secondary-wrapper a:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

@media (max-width: 1040px) {
    #secondary-wrapper a {
        display: block;
        position: relative;
        text-decoration: none;
        color: var(--white);
        border-radius: 40px;
        background: transparent;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: bold;
        padding: 0px 8px;
        width: 190px;
        overflow: hidden;
    }
}