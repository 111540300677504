.readmore-btn {
    width: fit-content;
    display: flex;
    text-align: start;

    background-color: var(--heighlight-font);
    border-radius: 40px;

}

.readmore-btn:hover {
    background-color: transparent !important;
}

.readmore-icon {
    margin-left: 5px;
    font-size: 22px;
}

.readmore-btn a {
    display: block;
    min-width: 180px;
    position: relative;
    text-decoration: none;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px;
    width: 160px;
    overflow: hidden;
}

.readmore-btn a span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.readmore-btn a:hover span {
    color: white;
}

.readmore-btn a::after,
.readmore-btn a::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.readmore-btn a::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.readmore-btn a:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.readmore-btn a::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.readmore-btn a:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}