.testimonials-swiper {
    margin-bottom: 80px;
    background-color: var(--white);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 30px;
    border-radius: 25px;
}

.swiper {
    width: 100%;
    margin-bottom: 20px;
}

/* swiper button */
.swiper-button {
    gap: 5px;
    margin: 20px 0px;
}

.prev-arrow {
    padding: 8px 15px;
    display: flex;
    align-items: center;
    font-size: 17px;
    color: var(--white);
    background-color: var(--heighlight-font);
    border-radius: 20px 0px 0px 20px;
}

.prev-arrow:hover {
    background-color: var(--main-font);
}

.next-arrow:hover {
    background-color: var(--main-font);
}

.next-arrow {
    padding: 8px 15px;
    display: flex;
    font-size: 17px;
    align-items: center;
    background-color: var(--heighlight-font);
    color: var(--white);
    border-radius: 0px 20px 20px 0px;
}

/* slide content */
.testimonials-slide-main {
    padding: 25px 35px;
    border-right: 1px solid var(--bg-color);
}

.testimonials-slide-main p {
    font-size: 17px;
    color: var(--secondary-color);
}

.testi-dec {
    min-height: 250px;
}

.testimonials-img {
    width: 20%;
    height: 100%;
}

.testimonials-img .testimonials-images {
    margin-right: 20px;
    border-radius: 50%;
    /* width: 100%; */
    /* height: 100%; */
    overflow: hidden;
    aspect-ratio: auto;
    max-width: 100%;
    max-height: 100%;
}

.testimonials-details {
    width: 78%;
}

.testimonials-details p {
    font-size: 16px;
    margin: 0px;
    color: var(--secondary-color);
    display: inline-block;
}

.testimonials-details h3 {
    margin: 0px;
    padding-bottom: 5px;
    font-size: 20px;
    color: var(--main-font);
    font-weight: 800;
}

@media (max-width: 350px) {
    .testimonials-slide-main {
        padding: 25px 5%;
    }
}