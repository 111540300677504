.Page-Section-Secondary p {
    margin: 0%;
    color: var(--main-font);
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 3.5%;
    text-align: center !important;
}

.change-size p {
    margin: 0%;
    color: var(--main-font);
    font-size: 20px;
    font-weight: 900;
}

.simple-p-tag {
    margin: 3% 8%;
    text-align: center;
    line-height: 1.5;
    color: var(--secondary-color);
}

@media screen and (max-width: 550px) {
    .Page-Section-Secondary {
        margin: 0%;
        text-align: center !important;
    }

    .Page-Section-Secondary p {
        font-size: 28px;
    }
}

@media screen and (max-width: 450px) {
    .Page-Section-Secondary p {
        font-size: 23px;
    }
}