.card-opening-main {
    margin-bottom: 20px;
}

.card-opening-main {
    position: relative;
}

.card-opening {
    padding: 25px;
    border-radius: 10px;
    position: relative;
    width: 32%;
    background-color: var(--bg-color);
    /* margin: 10px; */
}

.card-opening-header {
    color: var(--main-font);
    margin-top: 0%;
    font-size: 20px;
    font-weight: 500;
}

.card-opening-content {
    width: 50%;
    display: flex;
    align-items: center;

}

.card-opening-content p {
    margin: 0%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card-opening-content p span {
    color: var(--black);
    font-weight: 700;
}

.card-opening-icon {

    color: var(--heighlight-font) !important;
    align-items: center;
    margin-right: 4px;
}

.card-opening-hr {
    color: #ddd;
    margin: 20px 4px;
    margin-bottom: 0px;
}

.card-opening-button-content {
    margin-top: 30px;
}

.card-opening-button {
    width: 160px;
}

.card-opening-badge {
    position: absolute;
    text-align: center;
    color: var(--white);
    font-weight: 800;
    width: 29px;
    background-color: var(--heighlight-font);
    border-radius: 20px;
    border: 4px solid var(--white);
    right: -5px;
    top: -7px;

}

.job-dcription {
    margin-top: 10px;
    gap: 3px;
    min-height: 160px;
}

.job-dcription p {
    width: 89%;
    margin: 0;
    text-align: start;
    color: var(--black);
}

.job-dcription-icon {
    color: var(--heighlight-font);
}

/* secondary button  */

.second-button {
    width: 160px;
    display: flex;
    text-align: center;
    margin: 0 auto;
    text-align: center;
    background-color: var(--main-font);
    border-radius: 5px;
}

.second-button:hover {
    background-color: transparent !important;
    cursor: pointer;
}

.secondary-wrapper-icon {
    margin-right: 5px;
}

.second-button div {
    display: block;
    position: relative;
    text-decoration: none;
    color: var(--white);
    background: transparent;
    font-size: 15px;
    font-weight: bold;
    padding: 11px 15px;
    border-radius: 5px;
    width: 160px;
    overflow: hidden;
}

.second-button div span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.second-button div:hover span {
    color: white;
}

.second-button div::after,
.second-button div::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--heighlight-font);
    z-index: -1;
}

.second-button div::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.second-button div:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.second-button div::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.second-button div:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

/* Pop-up css */
.dialog-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #85858552;
    display: flex;
    align-items: center;
    overflow-y: auto;
    justify-content: center;
    z-index: 9999999;
}

.dialog-inner {
    position: relative;
    border-radius: 15px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    max-width: 60%;
    width: 70%;
    height: 85%;
    overflow: auto;
    padding: 16px 24px;
}

.dialog-icon {
    display: flex;
    align-items: center;
}

.dialog-icon .icon {
    font-size: 27px;
    color: var(--main-font);
}

.dialog-icon .icon:hover {
    color: #ff00008a;
    cursor: pointer;
}


@media (max-width: 1200px) {
    .card-opening {
        width: 48% !important;
    }
}

@media (max-width: 880px) {
    .second-button {
        margin: 0 ;
    }
    
    .card-opening-main {
        justify-content: center;
    }

    .card-opening {
        width: 100% !important;
    }

    .dialog-inner {
        max-width: 95% !important;
        width: 95% !important;
    }
}


@media (max-width: 550px) {
    .card-opening {
        width: 100% !important;
    }

    .dialog-heading {
        width: 90%;
    }
}

@media (max-width: 430px) {
    .card-opening-button{
        width: 130px;
    }
    .second-button{
        width: 140px;
    }
}
@media (max-width: 400px) {
    .second-button {
        width: 52%;

    }
}
@media (max-width: 355px) {
    .card-opening-button-content {
       justify-content: center;
       row-gap: 10px;
    }
}