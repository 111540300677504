.techcard-content {
    background-color: var(--bg-color);
    border-radius: 20px;
    align-items: flex-start;
    margin-bottom: 25px;
}

.techcard-content-left {
    background-color: var(--bg-color);
    border-radius: 20px;
    align-items: flex-start;
    margin-bottom: 25px;
}

.techcard-content-img {
    width: 50%;
    height: 100%;
}

.techcard-content-img2 {
    width: 50%;
    height: 100%;
}

.techcard-content-img .techcard-upper-img {
    border-radius: 20px 0px 0px 20px;
    width: 100%;
    height: 100%;
    margin-bottom: -6px;
}

.techcard-content-img2 .techcard-lower-img {
    border-radius: 0px 20px 20px 0px;
    width: 100%;
    height: 100%;
    margin-bottom: -6px;
    min-height: 615px;
}

.techcard-second-content {
    width: 50%;
    height: 100%;
    padding: 10px 40px;
}

.techcard-second-content h2 {
    color: var(--main-font);
    font-weight: 800;
}

.techcard-second-content p {
    color: var(--black);
    font-size: 15px;
}

.tech-details {
    margin-bottom: 10px;
    align-items: flex-start;
}

.tech-img {
    width: 10%;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    border-radius: 13px;
    text-align: center;
    background-color: var(--white);
}

.tech-img img {
    width: 88%;
    height: 100%;
}

.tech-content {
    width: 87%;
}

.tech-content p {
    margin: 0%;
    text-align: left !important;
}

.tech-content a {
    text-decoration: none;
    color: var(--heighlight-font);
    font-weight: 600;
    display: flex;
    align-items: center;
}

@media (max-width: 1000px) {
    .techcard-content {
        flex-direction: column-reverse;
    }

    .techcard-content-left {
        flex-direction: column;
    }

    .techcard-content-img {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .techcard-content-img2 {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .techcard-content-img .techcard-upper-img {
        width: 50%;
        border-radius: 20px;
    }

    .techcard-content-img2 .techcard-lower-img {
        width: 50%;

        min-height: 100%;
        border-radius: 20px;
    }

    .techcard-second-content {
        width: 100%;
    }

    .techcard-second-content h2 {
        text-align: center;
    }

}

@media (max-width: 500px) {

    .tech-details {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .tech-img {
        width: 15%;
        margin: 10px;
    }
}