.apply-form-main {
    width: 100%;
    background-color: var(--white);
    padding: 10px 0px;
    margin: auto;
    border-radius: 20px;
}

.apply-form-group {
    width: 48%;
    margin-bottom: 10px;
}

.apply-dropdown {
    width: 100%;
    margin: 15px 0px;

}

.apply-message {
    width: 100%;
    margin-bottom: 10px;
}

.contect-main h1 {
    color: var(--heighlight-font);
}

.apply-form-group {
    margin: 5px 0px;
}

label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--main-font);
    margin-bottom: 5px;
}

input[type="file"] {
    width: 100%;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 5px;
}

input::file-selector-button {
    font-weight: bold;
    color: var(--main-font);
    padding: 11px;
    background-color: var(--bg-color);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

textarea:focus,
.apply-form-group input:focus {
    outline: none;
}


.apply-form-group input[type="text"],
.apply-form-group input[type="email"],
.apply-form-group input[type="tel"],
.apply-message textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 5px;
}


.apply-form-group p {
    margin: 0%;
    color: #ff0000;
    font-weight: 700;
    font-size: 14px;
}
textarea {
    height: 100px;
}

/* Dropdown css */

.select-apply-form-btn {
    position: relative;
    display: inline-block;
    width: 48%;
}

.select-apply-form-btn select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--bg-color);
    background-color: var(--bg-color);
    border-radius: 5px;
    appearance: none;
    /* Remove the default arrow in some browsers */
    height: 42px;
    cursor: pointer;
}

/* Styles for the dropdown options */
.select-apply-form-btn select option {
    padding: 10px;
    background-color: var(--bg-color);
    border-radius: 5px;
    font-weight: 700;
    font-size: 15px;
    color: var(--main-font);
}

/* Style for the selected option */
.select-apply-form-btn::after {
    content: "\02EF";
    /* Unicode code for down arrow */
    position: absolute;
    color: var(--main-font);
    font-size: 50px;
    vertical-align: middle;
    align-items: center;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

/* Style for the select project-form-btn label (if needed) */
.select-label {
    font-weight: bold;
}

/* Apply Button  */

.apply-form-btn {
    width: 17%;
    display: flex;
    text-align: center;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    background-color: var(--heighlight-font);
    border-radius: 40px;
}

.apply-form-btn:hover {
    background-color: transparent !important;
}

.apply-form-btn button {
    border: none;
    display: block;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    padding: 10px 15px;
    width: 155px;
    overflow: hidden;
}

.apply-form-btn button span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
}

.apply-form-btn a:hover span {
    color: white;
}

.apply-form-btn button::after,
.apply-form-btn button::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.apply-form-btn button::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.apply-form-btn button:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.apply-form-btn button::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.apply-form-btn button:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

@media screen and (max-width: 1300px) {
    .apply-form-btn {
        width: 22% !important;
    }
}

@media screen and (max-width: 1030px) {
    .apply-form-btn {
        width: 30% !important;
    }
}

@media screen and (max-width: 960px) {
    .project-form-main {
        width: 100% !important;
    }
}

@media screen and (max-width:800px) {
    .apply-form-btn {
        width: 22% !important;
    }
}


@media screen and (max-width: 650px) {
    .apply-form-group {
        width: 48%;
        margin-bottom: 20px;
    }

    .apply-form-btn {
        width: 28% !important;
    }
}

@media screen and (max-width: 580px) {
    .apply-form-btn {
        width: 33% !important;
    }
}

@media screen and (max-width: 490px) {
    .apply-form-group {
        width: 45%;
        margin-bottom: 20px;
    }

    .apply-form-btn {
        width: 45% !important;
    }
}

@media screen and (max-width: 450px) {
    .apply-form-group {
        width: 100%;
        margin-bottom: 20px;
    }
}