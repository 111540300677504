.Getstarted-btn {
    width: fit-content;
    display: flex;
    background-color: var(--heighlight-font);
    border-radius: 40px;
    justify-content: center;

}
.btn-arr {
    display: flex;
    justify-content: center;
}
.Getstarted-btn:hover {
    background-color: transparent !important;
}

.readmore-icon {
    margin-left: 5px;
    font-size: 22px;
}

.Getstarted-btn a {
    display: block;
    min-width: 180px;
    position: relative;
    text-decoration: none;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 9px 15px;
    width: 160px;
    overflow: hidden;
}

.Getstarted-btn a span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Getstarted-btn a:hover span {
    color: white;
}

.Getstarted-btn a::after,
.Getstarted-btn a::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.Getstarted-btn a::before {
    transform: translateX(-100%);
    z-index: 1;
}

.Getstarted-btn a:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.Getstarted-btn a::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.Getstarted-btn a:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

.image-bg-one {
    background-image:  url('../../../src/image/TechnologiesNew/Hire_group_img.jpg');
    background-size: cover;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 40px; /* Optional: Adjust padding as needed */
}
.image-bg-two {
    background-image:  url('../../../src/image/TechnologiesNew/Hire_laptop_img.jpg');
    background-size: cover; /* Ensures the image covers the entire div */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 40px; /* Optional: Adjust padding as needed */
}


@media (max-width: 400px) {
    .image-bg-two {
      
        padding: 40px 10px; 
    }
    .image-bg-one {
      
        padding: 40px 10px; 
    }
}