@mixin box($width, $height, $background-color) {
    width: $width;
    height: $height;
    background-color: $background-color;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;
}

.nav-show {
    background-color: var(--white) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.nav-logo {
    width: 15%;
    min-width: 15%;
    margin: 10px;
}

.logo {
    align-items: center;
    text-align: center;
    margin-left: 8px;
    width: 75px;
    height: 55px;
}

nav .nav-main {
    position: relative;
    padding: 0px 5%;
    height: 69px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-main .logo a {
    color: #f2f2f2;
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
}

.nav-button {
    padding-right: 0px;

}

.nav-main .nav-links {
    display: inline-flex;
}

.nav-links li {
    list-style: none;
}

.nav-links {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: end;
}

.nav-links li a {
    color: var(--main-font);
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 0px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.nav-links li a:hover {
    color: var(--heighlight-font);
}

.nav-links li a:active {
    color: var(--heighlight-font) !important;
}

.drop-active {
    color: var(--heighlight-font) !important;

}

.nav-links .mobile-item {
    display: none;
}

.nav-links .mega-box-contect {
    position: absolute;
    background: var(--white);
    width: 35%;
    height: auto;
    top: 50px;
    z-index: 999999999999;
    border-radius: 20px;
    right: 8%;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}


.nav-links li:hover .mega-box {
    transition: all 0.3s ease;
    top: 45px;
    left: 330px;
    padding-top: 23px;
    opacity: 1;
    display: flex;
    visibility: visible;
}

.nav-links li:hover .mega-box-technologies {
    transition: all 0.3s ease;
    top: 45px;
    left: 515px;
    padding-top: 23px;
    opacity: 1;
    display: flex;
    visibility: visible;
}
.nav-links li:hover .mega-box-hire {
    transition: all 0.3s ease;
    top: 45px;
    left: 610px;
    padding-top: 23px;
    opacity: 1;
    display: flex;
    visibility: visible;
}

.nav-links li:hover .mega-box-contect {
    transition: all 0.3s ease;
    top: 45px;
    right: 9%;
    background-color: transparent;
    padding-top: 23px;
    opacity: 1;
    display: flex;
    visibility: visible;
    box-shadow: none;

}

.nav-links li:hover .drop-menu {
    transition: all 0.3s ease;
    top: 50px;
    opacity: 1;
    display: flex;
    padding: 20px;
    visibility: visible;
    background-color: var(--white);
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.drop-menu li {
    width: 50%;

}

.drop-menu li a {
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
    font-size: 16px;
    color: var(--heighlight-font);
    font-weight: 400;
    line-height: 18px;
    border-radius: 0px;
}

.drop-menu li a span {
    font-size: 14px;
    color: var(--main-font);
    margin: 0%;
    font-weight: 400;
}

.mega-box {
    position: absolute;
    right: 230px;
    width: 70%;
    height: auto;
    padding: 0 30px;
    top: 50px;
    z-index: 99999999999999;
    opacity: 0;
    visibility: hidden;
}

.mega-box-technologies {
    position: absolute;
    right: 400px !important;
    width: 55%;
    height: auto;
    padding: 0 30px;
    top: 50px;
    opacity: 0;
    visibility: hidden;
}
.mega-box-hire {
    position: absolute;
    right: 610px !important;
    width: 24%;
    height: auto;
    padding: 0 30px;
    top: 50px;
    opacity: 0;
    visibility: hidden;
}

.mega-box .content {
    background: var(--white);
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.mega-box-technologies .content {
    background: var(--white);
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.mega-box-hire .content {
    background: var(--white);
    padding: 25px 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.mega-box .content .row {
    width: calc(25%);
    margin-bottom: 10px;
}

.mega-box-technologies .content .row {
    width: 25%;
    margin-bottom: 10px;
}
.mega-box-hire .content .row {
    width: 100%;
    margin-bottom: 10px;
}

.content .row header a {
    color: var(--heighlight-font);
    font-size: 16px;
    font-weight: 500;
}

.content .row header {
    height: 35px;
}

.content .row .mega-links {
    padding-left: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.row .mega-links li {
    padding: 4px 20px;
    padding-right: 0%;
    margin: 0%;
}

.row .mega-links li a {
    padding: 0px;
    color: var(--secondary-color);
    font-size: 14px;
    font-weight: 500;
    display: block;
}

.row .mega-links li a:hover {
    color: var(--heighlight-font);
}

.nav-main .btn {
    color: var(--black);
    font-size: 20px;
    cursor: pointer;
    display: none;
}

.nav-main .btn.close-btn {
    position: absolute;
    right: 30px;
    top: 10px;
}

/* active link  */

.active .bar:first-child {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.active .bar:nth-child(2) {
    opacity: 0;
}

.active .bar:last-child {
    transform: rotate(45deg) translate(-5px, -6px);
}

/* Hover and active link */

.active-link {
    color: var(--heighlight-font) !important;
    font-weight: bold;
}

.arrow-up::after {
    content: '';
    position: absolute;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent var(--heighlight-font) transparent;
    background-color: transparent;
    top: 4px;
    left: 43%;
}
.arrow-up-hire::after {
    content: '';
    position: absolute;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent var(--heighlight-font) transparent;
    background-color: transparent;
    top: 4px;
    left: 43%;
}

.arrow-up-technologies::after {
    content: '';
    position: absolute;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent var(--heighlight-font) transparent;
    background-color: transparent;
    top: 4px;
    left: 41%;
}

.arrow-up-contect::after {
    content: '';
    position: absolute;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent var(--heighlight-font) transparent;
    background-color: transparent;
    top: 4px;
    left: 63%;
}

.life-with {
    display: none;
}

@media screen and (max-width: 1650px) {
    .arrow-up::after {
        left: 60%;
    }
    .arrow-up-hire::after {
        left: 50%;
    }

    .arrow-up-technologies::after {
        left: 63%;
    }

    .arrow-up-contect::after {
        left: 68%;
    }
    .nav-links li:hover .mega-box-hire {
      
        left: 800px;
      
    }
}

@media screen and (max-width: 1590px) {
    .arrow-up::after {
        left: 56%;
    }
    .arrow-up-hire::after {
        left: 48%;
    }
}

@media screen and (min-width: 1600px) {
    nav .nav-main {
        max-width: 1600px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 1510px) {
    .arrow-up::after {
        left: 52%;
    }
    .arrow-up-hire::after {
        left: 46%;
    }

    .arrow-up-technologies::after {
        left: 53%;
    }
    .nav-links li:hover .mega-box-hire {
      
        left: 738px;
      
    }
}

@media screen and (max-width: 1440px) {
    .arrow-up::after {
        left: 47%;
    }
    .arrow-up-hire::after {
        left: 29%;
    }
}

@media screen and (max-width: 1390px) {
    .arrow-up::after {
        left: 38%;
    }
    .arrow-up-hire::after {
        left: 48%;
    }

    .arrow-up-technologies::after {
        left: 48%;
    }
    .nav-links li:hover .mega-box-hire {
      
        left: 600px;
      
    }
}

@media screen and (max-width: 1340px) {
    .arrow-up::after {
        left: 42%;
    }
    .arrow-up-hire::after {
        left: 42%;
    }

    .arrow-up-contect::after {
        left: 60%;
    }
}

@media screen and (max-width: 1310px) {
    .arrow-up::after {
        left: 41%;
    }
    .arrow-up-hire::after {
        left: 34%;
    }

    .arrow-up-technologies::after {
        left: 40%;
    }
}

@media screen and (max-width: 1275px) {
    .arrow-up::after {
        left: 39%;
    }
    .arrow-up-hire::after {
        left: 26%;
    }
}

@media screen and (max-width: 1255px) {
    .arrow-up::after {
        left: 37%;
    }
    .arrow-up-hire::after {
        left: 24%;
    }

    .arrow-up-technologies::after {
        left: 30%;
    }
}

@media screen and (max-width: 1245px) {
    .arrow-up::after {
        left: 35%;
    }
    .arrow-up-hire::after {
        left: 23%;
    }
}

@media screen and (max-width: 1200px) {
    .arrow-up::after {
        left: 33%;
    }
    .arrow-up-hire::after {
        left: 21%;
    }
}

@media screen and (max-width: 1190px) {
    .arrow-up::after {
        left: 30%;
    }
    .arrow-up-hire::after {
        left: 19%;
    }

    .arrow-up-contect::after {
        left: 55%;
    }

}

@media screen and (max-width: 1150px) {
    .arrow-up::after {
        left: 27%;
    }
    .arrow-up-hire::after {
        left: 27%;
    }

    .arrow-up-technologies::after {
        left: 20%;
    }
}

@media screen and (max-width: 1120px) {
    .arrow-up::after {
        left: 24%;
    }
}

@media screen and (max-width: 1095px) {
    .arrow-up::after {
        left: 20%;
    }
    .arrow-up-hire::after {
        left: 20%;
    }

    .arrow-up-technologies::after {
        left: 15%;
    }
}

@media screen and (max-width: 1040px) {
    .life-with {
        display: block; 
    }
   
    .nav-button {
        width: 60%;
    }

    .nav-main .btn {
        display: block !important;
    }
    .career-label{
        padding: 0% !important;
    }

    .nav-main .nav-links {
        position: fixed;
        height: 100vh;
        width: 100%;
        max-width: 350px;
        top: 0;
        left: -100%;
        background: var(--white);
        display: block;
        padding: 50px 10px;
        line-height: 50px;
        overflow-y: auto;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
        transition: all 0.3s ease;
    }

    /* custom scroll bar */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: var(--white);
    }

    ::-webkit-scrollbar-thumb {
        background: var(--heighlight-font);
    }

    #menu-btn:checked~.nav-links {
        left: 0%;
    }

    #menu-btn:checked~.btn.menu-btn {
        display: none;
    }

    #close-btn:checked~.btn.menu-btn {
        display: block;
    }

    .nav-links li {
        margin: 15px 10px;
    }

    .nav-links li a {
        padding: 0 20px;
        display: block;
        font-size: 20px;
    }

    .nav-links .drop-menu {
        position: static;
        opacity: 1;
        top: 65px;
        visibility: visible;
        display: flex;
        padding-left: 20px;
        width: 100%;
        max-height: 0px;
        overflow: hidden;
        box-shadow: none;
        transition: all 0.3s ease;
    }



    .nav-links .mega-box-contect {
        opacity: -1;
    }





    #showDrop:checked~.drop-menu,
    #showMega:checked~.mega-box {
        max-height: 100%;
        width: 100%;
    }

    #showDrop:checked~.drop-menu,
    #showMega-tech:checked~.mega-box-technologies {
        max-height: 100%;
        width: 100%;
    }
    #showDrop:checked~.drop-menu,
    #showMega-tech:checked~.mega-box-hire {
        max-height: 100%;
        width: 100%;
    }

    #showDrop:checked~.drop-menu,
    #showDrop:checked~.mega-box-contect {
        max-height: 100%;
        width: 100%;
    }

    .nav-links .desktop-item {
        display: none;
    }

    .nav-links .mobile-item {
        display: block;
        color: var(--main-font);
        font-size: 20px;
        font-weight: 800 !important;
        padding-left: 20px;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.3s ease;
    }

    .nav-links .mobile-item:hover {
        background: var(--white);
    }

    .drop-menu li {
        margin: 0;
    }

    .drop-menu li a {
        border-radius: 5px;
        font-size: 18px;
    }

    .mega-box,
    .mega-box-technologies,
    .mega-box-hire,
    .mega-box-contect {
        position: static;
        top: 65px;
        opacity: 1;
        visibility: visible;
        padding: 0 20px;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    .mega-box .content,
    .mega-box-technologies .content,
    .mega-box-hire .content,
    .mega-box-contect .content {
        box-shadow: none;
        flex-direction: column;
        padding: 20px 20px 0 20px;
    }


    .mega-box .content .row,
    .mega-box-technologies .content .row,
    .mega-box-hire .content .row,
    .mega-box-contect .content .row {
        width: 100%;
        margin-bottom: 15px;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
    }

    .mega-box .content .row:nth-child(1),
    .mega-box-technologies .content .row:nth-child(1),
    .mega-box-hire .content .row:nth-child(1),
    .mega-box-contect .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2),
    .mega-box-technologies .content .row:nth-child(2),
    .mega-box-hire .content .row:nth-child(2),
    .mega-box-contect .content .row:nth-child(2) {
        border-top: 0px;
    }

    .content .row .mega-links {
        border-left: 0px !important;
        padding-left: 15px !important;
    }

    .content .row header a {
        font-size: 16px;
    }

    .row .mega-links li a {
        font-size: 15px !important;
        line-height: 25px !important;
    }

    .mega-box .content {
        padding: 0px;
    }

    .mega-box-technologies .content {
        padding: 0px;
    }
    .mega-box-hire .content {
        padding: 0px;
    }

    .mega-box-contect .content {
        padding: 0px;
    }

    .row .mega-links li {
        margin: 0 !important;
    }

    .content .row header {
        font-size: 19px;
    }

    .arrow-up::after {
        top: -18px
    }
    .arrow-up-hire::after {
        top: -18px
    }

    .arrow-up-technologies::after {
        top: -18px
    }

    .arrow-up-contect::after {
        top: -80px
    }

    .nav-links li:hover .drop-menu {
        opacity: -1;
    }
}

nav input {
    display: none;
}

.body-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 30px;
}

.body-text div {
    font-size: 45px;
    font-weight: 600;
}