/* Overall container */
.slider-container {
    position: relative;
    width: 100%;
    padding: 20px;
}

/* Item container */
.slider-item {
    padding: 10px;
}

/* Card styling */
.slider-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* transition: transform 0.3s ease; */
    min-height: 250px;
}

/* .slider-card:hover {
    transform: scale(1.05);
} */

/* Image icon styling */
.card-icon {
    margin-bottom: 10px;
}

/* Title and description styling */
.slider-card h3 {
    font-size: 18px;
    color: #333;
    margin: 10px 0;
}

.slider-card p {
    font-size: 14px;
}

.custom-navigation-buttons {

    display: flex;
    justify-content: end;
    margin-top: 20px;
    gap: 10px;
    /* Space between buttons */
}

/* Button Design - Mimicking the Design in the Image */
.custom-swiper-prev,
.custom-swiper-next {
    background-color: white;
    color: #007bff;
    border: 2px solid #007bff;
    font-size: 18px;
    padding: 8px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.custom-swiper-prev:hover,
.custom-swiper-next:hover {
    background-color: #007bff;
    color: white;
}

@media (max-width: 1350px) {
    .slider-card {
        min-height: 260px;
    }
}
