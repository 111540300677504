.acc-kontainer {
    width: 100%;
    margin: auto;
}

.acc-kontainer .acc-body {
    width: 98%;
    width: calc(100% - 20px);
    margin: 0 auto;
    height: 0;
    color: rgba(0, 0, 0, 0);
    background-color: var(--white);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    line-height: 28px;
    padding: 0 20px;
    box-sizing: border-box;
    transition: 0.5s;
}

.acc-kontainer label {
    cursor: pointer;
    background-color: var(--bg-color);
    border-bottom: 1px solid var(--bg-color);
    display: block;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    color: var(--main-font);
    font-weight: 800;
    box-sizing: border-box;
    z-index: 100;
}

.acc-kontainer input {
    display: none;
}

.acc-kontainer label:before {
    content: '\002B';
    font-size: 20px;
    border-radius: 16px;

    color: var(--main-font) !important;
    padding: 1px 8px;
    font-weight: bolder;
    float: right;
}

.acc-kontainer input:checked+label {
    background-color: var(--bg-color);
    color: var(--heighlight-font) !important;
}

.acc-kontainer input:checked+label:before {

    content: '\002D';
    transition: 0.5s;
    font-size: 17px;
    padding: 5px 7px;
}

.acc-kontainer input:checked~.acc-body {
    height: 100%;
    margin-bottom: 5px;
    color: var(--main-font);
    font-size: 16px;
    padding: 15px;
    transition: 0.5s;
}