*,
*:before,
*:after {
    box-sizing: border-box;
}

.footer-main {
    display: flex;
    flex-flow: row wrap;
    padding: 10px 5%;
    background-color: var(--bg-color);
    border-top: 1px solid #e5e5e5;
}

.footer-main>* {
    flex: 1 100%;
}

.footer__addr {
    max-width: 250px;
    margin-bottom: 2em;
}

.footer__logo {
    width: 30%;
    height: 100%;
}

.footer__logo img {
    margin-left: 18px;
    width: 75px;
    height: 55px;
}

.footer-center .footer-subheader {
    color: var(--main-font);
    font-weight: 800;
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 18px;
}

.getintouch {
    margin-bottom: 7px;
}

.getintouch a {
    padding-left: 10px;
}


.footer-flge {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.nav__title {
    font-weight: 800;
    font-size: 20px;
    color: var(--main-font);
}

.footer-header {
    width: 22%;
    height: 63%;
    padding-top: 5px;
}

.footer-header h2 {
    font-weight: 800;
    margin-top: 0px;
    font-size: 20px;
    color: var(--main-font);
}

.footer-content h2 {
    font-weight: 800;
    margin: 0%;
    font-size: 20px;
    color: var(--main-font);
}

.footer-content-header {
    font-weight: 800;
    margin: 0px;
    margin-bottom: 2px;
    font-size: 16px;
    margin-left: 50px;
    color: var(--main-font);
}

.footer-visit-address {
    font-weight: 500;
    margin-top: 0px;
    line-height: 22px;
    width: 80%;
    text-align: start;
    font-size: 14px;
    margin-left: 50px;
    color: var(--main-font);
}

.footer-visit div {
    width: 100%;
    display: flex;
    align-items: center;
}

.footer-main ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
}

.footer-link-list {
    width: 55%;
}

.footer-main li {
    padding-bottom: 10px;
}

.footer-main a {
    text-decoration: none;
}

.footer__nav {
    display: flex;
    flex-flow: row wrap;

}

.footer__nav>* {
    flex: 1 50%;
    margin-left: 50px;
}

.nav__ul li:hover a {
    color: var(--heighlight-font);

}

.nav__ul a {
    color: var(--main-font);
    font-weight: 600;
    font-size: 15px;
}

.legal {
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 0px;

}

.legal div {
    width: 90%;
    /* margin-left: 7%; */
}

.legal div span {
    color: var(--main-font);
    font-weight: 700;
}



.legal__links {
    display: flex;
    align-items: center;
}


.footer-center div {
    width: 100%;
    display: flex;
    align-items: center;
}

.footer-center .footer-center-icon {
    background-color: var(--main-font);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    line-height: 42px;
    margin: 10px;
    margin-left: 0px;
    padding: 5px;
    vertical-align: middle;
}

.footer-center p {
    display: inline-block;
    color: var(--main-font);
    font-weight: 600;
    font-size: 14px;
    vertical-align: middle;
    margin: 0;
}

.footer-center p span {
    display: block;
    font-weight: normal;
    font-size: 14px;
    font-weight: 600;
    line-height: 2;
}

.footer-center a {
    color: var(--main-font) !important;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
}

.footer-icons {
    margin-top: 50px;
    position: relative;
    text-align: start;
    width: 100%;
}

.social-ul {
    display: flex;
    position: absolute;
    top: 50%;
    z-index: 1;
    left: 115px !important;
    transform: translate(-50%, -50%);
}

.social-ul li {
    list-style: none;
}

.social-ul li a {
    width: 40px;
    height: 40px;
    background-color: var(--white);
    text-align: center;
    line-height: 42px;
    font-size: 20px;
    margin: 0 10px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 3px solid var(--white);
    z-index: 1;
}

.social-ul li a .social-icon {
    position: relative;
    color: #262626;
    transition: .5s;
    z-index: 3;
}

.social-ul li a:hover .social-icon {
    color: #fff;
    transform: rotateY(360deg);
}

.social-ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;

    transition: .5s;
    z-index: 2;
}

.social-main-header {
    display: block;

}

.social-ul li a:hover:before {
    top: 0;
}


.social-ul li:nth-child(1) a:before {
    background: #55acee;
}

.social-ul li:nth-child(2) a:before {
    background: #3b5999;
}


.social-ul li:nth-child(3) a:before {
    background: #cd486b;
}

.social-ul li:nth-child(4) a:before {
    background: #0077b5;
}

.footer-makeindia-logo {
    width: 6% !important;
    text-align: center;
}

.footer-makeindia-logo img {
    /* width: 78px;
    height: 35.5px; */
    aspect-ratio: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

@media screen and (min-width: 1600px) {
    .footer-main {
        padding: 20px 16%;
    }
}

@media screen and (min-width: 24.375em) {
    .legal .legal__links {
        margin-left: auto;
    }
}

@media screen and (min-width: 1100px) {

    .footer__nav>* {
        flex: 1;
    }

    .footer__addr {
        flex: 1 0px;
    }

    .footer__nav {
        flex: 2 0px;
    }
}

@media screen and (max-width: 1100px) {
    .footer-flge {
        width: 6%;
    }

    .footer-content-header {
        margin-left: 43px;
        margin-left: 7%;
    }

    .footer-visit-address {
        width: 50%;
        margin-left: 7%;
    }

    .legal div {
        width: 81%;
    }
}

@media screen and (max-width: 1040px) {

    .footer-hr {
        margin-top: 65px;
    }

    .footer-link-list {
        width: 100%;
    }

    .footer-header {
        width: 60%;
        margin-left: 7%;
    }

    .footer__nav>* {
        flex: 1;
    }

    .legal img {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 665px) {
    .footer-hr {
        margin-top: 110px;
    }
}

@media screen and (max-width: 640px) {
    .footer-makeindia-logo {
        width: 10% !important;
    }

    .footer-hr {
        margin-top: 40px;
    }

    .footer-flge {
        width: 9%;
    }

    .footer-content-header {
        margin-left: 8%;
    }

    .footer-visit-address {
        width: 70%;
    }

    .footer-header {
        margin-top: 25px;
        max-height: 260px;
    }

    .legal div {
        width: 75%;
    }

    .footer-makeindia-logo {
        width: 9%;
    }

    .footer__nav>* {
        flex: 1 !important;
    }

}

@media screen and (max-width: 480px) {
    .footer-link-list {
        width: 70%;
    }

    .footer-flge {
        width: 14%;
    }

    .footer-content-header {
        margin-left: 12%;
    }

    .footer-visit-address {
        width: 100%;
        margin-left: 12%;
    }

    .footer-makeindia-logo {
        width: 10%;
    }
}

@media screen and (max-width: 380px) {

    .legal div {
        width: 70%;
    }

    .footer-makeindia-logo {
        width: 13%;
    }
}

@media screen and (max-width: 310px) {


    .footer-content-header {
        margin-left: 14%;
    }

    .footer-visit-address {
        margin-left: 18%;
    }

    .footer-makeindia-logo {
        width: 15%;
    }
}