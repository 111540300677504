article {
    --img-scale: 1.001;
    --title-color: black;
    --link-icon-translate: -20px;
    --link-icon-opacity: 0;
    position: relative;
    border-radius: 16px;
    box-shadow: none;
    background: #fff;
    transform-origin: center;
    transition: all 0.4s ease-in-out;
    overflow: hidden;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}

/* basic article elements styling */
article h2 {
    margin: 0 0 18px 0;
    font-size: 1.6rem;
    color: var(--main-font);
    transition: color 0.3s ease-out;
    font-weight: 800;
}

figure {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;

}

article img {
    max-width: 100%;
    transform-origin: center;
    border-radius: 15px;
    transform: scale(var(--img-scale));
    transition: transform 0.4s ease-in-out;
}

.blog-img-name {
    position: absolute;
    right: 4%;
    bottom: 6%;
}

.blog-img-name p {
    background-color: var(--heighlight-font);
    color: var(--white);
    margin: 0px;
    font-size: 13px;
    padding: 4px 5px;
    font-weight: 400;
    border-radius: 5px
}

.blog-content-main {
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.blog-content {
    width: 50%;
    gap: 10px;
    align-items: center !important;
}

.blog-content p {
    margin: 0%;
    color: var(--heighlight-font) !important;
    font-weight: 600;
}

.blog-icon {
    color: var(--heighlight-font);
    font-size: 16px;

}

.blog-date {
    width: 48%;
    gap: 10px;
    align-items: center;
}

.blog-date p {
    color: var(--secondary-color);
    margin: 0%;
    font-weight: 600;
}

.article-body {
    padding: 24px;
    padding-bottom: 0px !important;
}

.article-body p {
    color: var(--secondary-color);
    font-weight: 400;
}

.blog-footer a {
    align-items: center;
    padding: 10px 40px;
    padding-top: 0px;
    text-decoration: none;
}

.blog-footer p {
    color: var(--heighlight-font);
    margin: 0px;
    font-size: 15px;
    font-weight: 600;

}

.blog-footer-icon {
    color: var(--heighlight-font);
}


/* using the has() relational pseudo selector to update our custom properties */
article:has(:hover, :focus) {
    --img-scale: 1.1;
    --title-color: var(--heighlight-font);
    --link-icon-translate: 0;
    --link-icon-opacity: 1;

}


/************************ 
  Generic layout (demo looks)
  **************************/

*,
*::before,
*::after {
    box-sizing: border-box;
}


.articles {
    display: grid;
    max-width: 1200px;
    margin-inline: auto;
    padding: 0px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 24px;
}



@media screen and (max-width: 960px) {
    article {
        container: card/inline-size;
    }
}

@container card (min-width: 380px) {
    /* .article-wrapper {
        display: grid;
        grid-template-columns: 100px 1fr;
        gap: 16px;
    }

    .article-body {
        padding-left: 0;
    }

    figure img {
        height: 100%;
        aspect-ratio: 1;
       height:100%;
        object-fit: cover;
    } */
}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}