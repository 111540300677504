.banner-main {
    margin-bottom: 20px;
    margin-top: 5%;
    background-image: linear-gradient(0deg, rgba(237, 240, 255, 70%), rgba(237, 240, 255, 0%));
    padding: 0% 5%;
    border-radius: 20px;
    height: auto;
}

.banner-bg {
    height: 100%;
    width: 53%;
}

.blog-banner {
    height: auto;
    width: 100%;
    text-align: center;
}

.banner-bg-heading {
    font-size: 58px;
    margin: 0%;
    line-height: 70px;
    font-weight: 600;
    color: var(--main-font);
}

.banner-bg-subheading {
    margin: 0%;
    font-weight: 500;
    padding-top: 25px;
    font-size: 18px;
    color: var(--black);
    text-align: start;
}

.banner-img {
    width: 30%;
    height: 100%;
}
.banner-img-max {
    width: 40%;
    height: 100%;
}

.banner-img .about-banner-img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}

/*  card css */

.card-cantent {
    width: 30%;
}


/* banner button css */

.bennar-btn {
    width: fit-content;
    display: flex;
    margin-top: 25px;
    text-align: start;
    background-color: var(--heighlight-font);
    border-radius: 40px;
    margin-bottom: 50px;

}

.bennar-btn:hover {
    background-color: transparent !important;
}

.bennar-icon {
    margin-left: 5px;
    font-size: 22px;
}

.bennar-btn a {
    display: block;
    min-width: 245px;
    position: relative;
    text-decoration: none;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    width: 160px;
    overflow: hidden;
}

.bannar-icon {
    margin-left: 10px;
    font-size: 25px;
}

.bennar-btn a span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}

.bennar-btn a:hover span {
    color: white;
}

.bennar-btn a::after,
.bennar-btn a::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.bennar-btn a::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.bennar-btn a:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.bennar-btn a::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.bennar-btn a:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

@media (min-width: 1600px) {
    .banner-main {
        padding: 2% 25%;
    }
}

@media (max-width: 1205px) {
    .banner-bg-heading {
        font-size: 50px;
    }
}

@media (max-width: 1315px) {
    .banner-bg-heading {
        font-size: 50px;
    }
}

@media (max-width: 1133px) {
    .banner-bg-heading {
        line-height: 50px;
        font-size: 40px;
    }
}

@media (max-width: 907px) {
    .banner-bg-subheading {
        padding-bottom: 25px;
    }

    .banner-main {
        flex-direction: column-reverse;
        margin-top: 10%;
    }

    .banner-img {
        width: 50%;
    }

    .banner-bg {
        width: 100%;
    }

    .banner-bg-heading {
        font-size: 40px;
    }

}

@media (max-width: 650px) {
    .banner-main {
        margin-top: 13%;
    }
}

@media (max-width: 520px) {
    .banner-main {
        margin-top: 90px;
    }

    .banner-bg-subheading {
        font-size: 16px;
    }

    .banner-bg-heading {
        font-size: 25px;
        line-height: 35px;
    }
}