.developmentskillcard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.developmentskillcard-main {
    background-color: var(--white);
    width: 32%;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
}

/* Extra width class for the second line */
.developmentskillcard-extra-width {
    width: calc(48%);
}

.developmentskillcard-main h4 {
    color: var(--main-font);
}

.developmentskillcard-main p {
    font-size: 14px;
}

@media screen and (max-width: 1115px) {
    .developmentskillcard-main {
        width: 48%;
    }

    .developmentskillcard-extra-width {
        width: calc(48%);
    }
}

@media screen and (max-width: 600px) {
    .developmentskillcard-main {
        width: 100%;
    }

    .developmentskillcard-extra-width {
        width: 100%;
    }
}
