.aboutcard-main {
    margin-top: 50px;
    align-items: flex-start !important;
}

.aboutcard-img {
    width: 45%;
    height: 100%;
}

.aboutcard-img .aboutcard-image {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}

.aboutcard-content {
    width: 55%;
    padding: 0px 50px;
    padding-left: 10px;

}

.aboutcard-content h2 {
    margin-top: 0%;
    display: flex;
    font-size: 20px;
    width: 35%;
    color: var(--heighlight-font);
    align-items: center;
}

.aboutcard-content h2 hr {
    width: 40px;
    height: 3px;
    border-radius: 5px;
    background-color: var(--heighlight-font);
    border: 2px solid var(--heighlight-font);
}

.aboutcard-content h1 {
    font-size: 40px;
    color: var(--main-font);
    font-weight: 900;
    padding-right: 50px;

}

.aboutcard-subtitle {
    text-align: left;
}

.aboutcard-content p {
    font-size: 21px;
    color: var(--main-font);
    font-weight: 700;
    padding-right: 50px;
}

.aboutcard-content .aboutcard-artical {
    font-size: 18px;
    font-weight: 400;
    border-radius: 0px;
    color: var(--secondary-color);
    background-color: transparent;
}

.aboutcard-button {
    padding-top: 6%;


}

@media (max-width: 1110px) {

    .aboutcard-content h1 {
        padding-right: 10%;
    }
}

@media (max-width: 1030px) {
    .aboutcard-main {
        flex-direction: column;
        align-items: center !important;
    }

    .aboutcard-content {
        width: 100% !important;
        margin-top: 20px;

    }

    .aboutcard-content h2 {
        margin-top: 0%;
        display: flex;
        width: 50%;
        color: var(--heighlight-font);
        align-items: center;
    }

    .aboutcard-content h1 {
        padding-right: 10%;
        font-size: 35px;
    }
}

@media (max-width: 860px) {
    .aboutcard-content h1 {
        font-size: 30px;
    }
}

@media (max-width: 780px) {
    .aboutcard-content {
        padding-right: 0%;
    }

    .aboutcard-content h1 {
        padding-right: 0%;
    }

    .aboutcard-content p {
        padding-right: 0%;
    }

    .aboutcard-content {
        padding-left: 0px;
    }
}

@media (max-width: 610px) {
    .aboutcard-content h1 {
        font-size: 24px;
    }
}

@media (max-width: 500px) {
    .aboutcard-content h1 {
        padding-right: 0%;
        font-size: 22px;
    }

    .aboutcard-button {
        padding-bottom: 40px;
        display: flex;
        justify-content: center;
    }
}