.project-form-main {
    width: 70%;
    background-color: var(--bg-color);
    padding: 20px 30px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
    margin-bottom: 20px;
    border-radius: 20px;
}

.form-feld {
    margin-top: 10px;
    align-items: flex-start;
}

.project-form-group {
    width: 31%;
    padding-bottom: 20px;
}

.contect-main h1 {
    color: var(--heighlight-font);
}

.project-form-group {
    margin-bottom: 10px;
}

label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--main-font);
    margin-bottom: 5px;
}

textarea:focus,
.project-form-group input:focus {
    outline: none;
}


input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--white);
    background-color: var(--white);
    border-radius: 5px;
}


.project-form-group p {
    margin: 0%;
    color: #ff0000;
    font-weight: 700;
    font-size: 14px;
}
.project-message-box {
    width: 65.5%;
}

textarea {
    height: 100px;
}

/* Dropdown css */

.select-project-form-btn {
    position: relative;
    display: inline-block;
    width: 100%;

}

.select-project-form-btn select {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--white);
    background-color: var(--white);
    border-radius: 5px;
    appearance: none;
    /* Remove the default arrow in some browsers */
    height: 42px;
    cursor: pointer;
}

/* Styles for the dropdown options */
.select-project-form-btn select option {
    padding: 10px;
    background-color: var(--bg-color);
    border-radius: 5px;
    font-weight: 700;
    font-size: 15px;
    color: var(--main-font);
}

/* Style for the selected option */
.select-project-form-btn::after {
    content: "\02EF";
    /* Unicode code for down arrow */
    position: absolute;
    color: var(--main-font);
    font-size: 50px;
    vertical-align: middle;
    align-items: center;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

/* Style for the select project-form-btn label (if needed) */
.select-label {
    font-weight: bold;
}


.project-form-btn {
    width: fit-content;
    display: flex;
    text-align: start;
    background-color: var(--heighlight-font);
    border-radius: 40px;
    margin-top: 20px;
}

.project-form-btn:hover {
    background-color: transparent !important;
}

.project-form-btn button {
    display: block;
    cursor: pointer;
    border: none;
    position: relative;
    text-decoration: none;
    color: var(--white);
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 9px;
    width: 130px;
    overflow: hidden;
}

.project-form-btn button span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-form-btn button:hover span {
    color: white;
}

.project-form-btn button::after,
.project-form-btn button::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.project-form-btn button::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.project-form-btn button:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.project-form-btn button::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.project-form-btn button:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}

#project-cantrycode .PhoneInput{
    background-color: var(--white);
}
#project-cantrycode .PhoneInput .PhoneInputCountry{
  border-right: 2px solid var(--bg-color);
}


@media screen and (min-width: 1600px) {
    .project-form-main {
        margin: 10px 16%;
    }

}

@media screen and (max-width: 960px) {
    .project-form-main {
        width: 100%;
    }

}

@media screen and (max-width: 650px) {
    .project-form-group {
        width: 48%;
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 450px) {
    .project-form-group {
        width: 100%;
        margin-bottom: 20px;
    }

    .project-form-btn {
        display: flex;
        justify-content: center !important;
    }

}