.symbocs-content {
    width: 25%;
    padding: 20px;
    text-align: center;
}

.symbocs-content img {
    width: 30%;
    height:100%;
}

.symbocs-content h3 {
    width: 100%;
    font-weight: 700;
    color: var(--main-font);
}

@media screen and (max-width: 800px) {
    .symbocs-content {
        width: 50%;

    }
}

@media screen and (max-width: 450px) {
    .symbocs-content {
        width: 100%;
    }

    .symbocs-content img {
        width: 25%;
    }
}