/* PageSectionMain css */

.PageSectionMain-content {
    margin-top: 3.5%;
}

.PageSectionMain-content div {
    margin: 0%;
    display: flex;
    width: 50%;
    color: var(--heighlight-font);
    font-size: 20px;
    font-weight: 800;
}

.PageSectionMain-content hr {
    width: 10%;
    border-radius: 10px;
    height: 1px;
    background-color: var(--heighlight-font);
    border-color: var(--heighlight-font);
}

@media screen and (max-width: 950px) {
    .PageSectionMain-content div {
        width: 60%;
    }
}

@media screen and (max-width: 770px) {
    .PageSectionMain-content div {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .PageSectionMain-content div {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .PageSectionMain-content div {
        font-size: 17px;
    }
}

@media screen and (max-width: 450px) {
    .PageSectionMain-content div {
        font-size: 16px;
    }
}