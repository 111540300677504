.job-description-main h3 {
    color: var(--main-font);
    margin-bottom: 0;
}

.job-description-list {
    list-style-type: square;
    color: var(--secondary-color);
    padding-bottom: 5px;
}

.job-description-list li {
    padding-bottom: 6px;
}

.job-description-main p {
    margin-top: 5px;
    color: var(--secondary-color);
}