.tech-fency-card {
    width: 32%;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    color: var(--heighlight-font);
    border-bottom: 6px solid var(--heighlight-font);
    margin-bottom: 40px;
    height: 200px;
    padding: 20px;
}

.tech-fency-card p {
    color: var(--secondary-color);
    margin-bottom: 0%;
}

.tech-fency-button {
    width: 32% !important;
    color: var(--white);
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-color: var(--heighlight-font);
    margin-bottom: 20px;
    padding-bottom: 25px;
}

.tech-fency-button a {
    color: var(--heighlight-font);
    border-radius: 25px;
    background-color: var(--white);
    text-decoration: none;
    padding: 11px 15px;
}

.tech-fency-button-icon {
    margin: 10px 5px 0px 0px;
}

@media (max-width: 1110px) {
    .tech-fency-card {
        width: 48%;

    }
}

@media (max-width: 700px) {
    .tech-fency-card {
        width: 100%;

    }
}