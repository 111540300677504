.techdetails {
    width: 48%;
    margin-bottom: 30px;
    margin-top: 20px;
}

.techdetails-icon {
    width: 5%;
    font-size: 28px;
    border-radius: 20px;
    padding: 2px;
    color: var(--heighlight-font);
    background-color: var(--bg-color);
}

.techdetails-title {
    width: 93%;
}

.techdetails-title h2 {
    margin: 0%;
    color: var(--main-font);
}

.techdetails-decription {
    margin-left: 7%;
}

@media (max-width: 700px) {

    .techdetails {
        width: 100%;
        margin-bottom: 10px;
    }

}