.tabs {
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    background: var(--white);
    padding: 50px;
    padding-top: 5px;
    padding-bottom: 80px;
    width: 100%;
    background-color: var(--white);
    height: 100%;
    border-radius: 5px;
    min-width: 240px;
}

.tabs input[name="tab-control"] {
    display: none;
}

.tabs .content section h2,
.tabs ul li label {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 18px;
    color: var(--main-font);
}

.tabs ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 15px 0px;
    background-color: var(--bg-color);
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.tabs ul li {
    box-sizing: border-box;
    flex: 1;
    width: 25%;
    padding: 0 10px;
    text-align: center;
}

.tabs ul li label {
    transition: all 0.3s ease-in-out;
    color: var(--main-font) !important;
    padding: 5px auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    cursor: pointer;
    font-size: 17px;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    -webkit-touch-callout: none;
}

.tabs ul li label br {
    display: none;
}

/* .tabs ul li label span {
    color: var(--secondary-color);
} */

.tabs ul li label svg {
    fill: #929daf;
    height: 1.2em;
    width: 30px;
    vertical-align: bottom;
    margin-right: 0.2em;
    transition: all 0.2s ease-in-out;
}

.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
    outline: 1;
    color: var(--secondary-color);
}

.tabs ul li label:hover svg,
.tabs ul li label:focus svg,
.tabs ul li label:active svg {
    fill: #bec5cf;
}

.tabs .slider {
    position: relative;
    width: 25%;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}

.tabs .slider .indicator {
    position: relative;
    width: 50px;
    max-width: 100%;
    margin: 0 auto;
    height: 4px;
    background: var(--heighlight-font);
    border-radius: 1px;
}

.tabs .content {
    margin-top: 30px;
}

.tabs .content section {
    display: none;
    animation-name: content;
    animation-direction: normal;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    line-height: 1.4;
}

.tabs .content section h2 {
    color: #428bff;
    display: none;
}

.tabs .content section h2::after {
    content: "";
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    background: #428bff;
    margin-top: 5px;
    left: 1px;
}

.tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
    cursor: default;
    color: #428bff;
}

.tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label svg {
    fill: #428bff;
    width: 30px;
}

.tab-tech-block {
    text-align: center;
}

.tab-tech-block a {
    text-decoration: none;
    width: 19%;
    height: 100%;
    padding: 20px;
}

.tab-tech-block a:hover {
    background-color: var(--bg-color);
    border-radius: 10px;
}

.tab-list-div-main {
    height: 50px;
    margin-bottom: 7px;

}

.tab-list-div-main .tab-list-div-img {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}

.tab-list-main p {
    margin: 0px;
    text-align: center;
    font-weight: bold; /* or the specific weight of h4 */
    color: var(--secondary-color);
}



@media (max-width: 600px) {
    .tabs input[name="tab-control"]:nth-of-type(1):checked~ul>li:nth-child(1)>label {
        background: rgba(0, 0, 0, 0.08);
    }
}

.tabs input[name="tab-control"]:nth-of-type(1):checked~.slider {
    transform: translateX(0%);
}

.tabs input[name="tab-control"]:nth-of-type(1):checked~.content>section:nth-child(1) {
    display: block;
}

.tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
    cursor: default;
    color: #428bff;
}

.tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label svg {
    fill: #428bff;
}

@media (max-width: 600px) {
    .tabs input[name="tab-control"]:nth-of-type(2):checked~ul>li:nth-child(2)>label {
        background: rgba(0, 0, 0, 0.08);
    }
}

.tabs input[name="tab-control"]:nth-of-type(2):checked~.slider {
    transform: translateX(100%);
}

.tabs input[name="tab-control"]:nth-of-type(2):checked~.content>section:nth-child(2) {
    display: block;
}

.tabs input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
    cursor: default;
    color: #428bff;
}

.tabs input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label svg {
    fill: #428bff;
}

@media (max-width: 600px) {
    .tabs input[name="tab-control"]:nth-of-type(3):checked~ul>li:nth-child(3)>label {
        background: rgba(0, 0, 0, 0.08);
    }
}

.tabs input[name="tab-control"]:nth-of-type(3):checked~.slider {
    transform: translateX(200%);
}

.tabs input[name="tab-control"]:nth-of-type(3):checked~.content>section:nth-child(3) {
    display: block;
}

.tabs input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
    cursor: default;
    color: #428bff;
}

.tabs input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label svg {
    fill: #428bff;
}

@media (max-width: 600px) {
    .tabs input[name="tab-control"]:nth-of-type(4):checked~ul>li:nth-child(4)>label {
        background: rgba(0, 0, 0, 0.08);
    }
}

.tabs input[name="tab-control"]:nth-of-type(4):checked~.slider {
    transform: translateX(300%);
}

.tabs input[name="tab-control"]:nth-of-type(4):checked~.content>section:nth-child(4) {
    display: block;
}

@keyframes content {
    from {
        opacity: 0;
        transform: translateY(5%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@media (max-width: 1000px) {
    .tabs ul li label {
        white-space: initial;
    }

    .tabs ul li label br {
        display: initial;
    }

    .tabs ul li label svg {
        height: 1.5em;
    }
}

@media (max-width: 700px) {
    .tab-tech-block a {
        width: 47%;
    }
}

@media (max-width: 485px) {
    .tab-tech-block a {
        width: 45%;
    }
}

@media (max-width: 600px) {
    .tabs ul li label {
        padding: 5px;
        border-radius: 5px;
    }

    .tabs ul li label span {
        display: none;
    }

    .tabs .slider {
        display: none;
    }

    .tabs .content {
        margin-top: 20px;
    }

    .tabs .content section h2 {
        display: block;
    }
}