.service-card-content {
    background-color: var(--bg-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.service-card-content-left {
    background-color: var(--bg-color);
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.service-card-img {
    width: 50%;
    height: 100%;
    text-align: end;
}

.service-card-img-left {
    width: 50%;
    height: 100%;
}

.service-card-img .service-card-image {
    border-radius: 0px 20px 20px 0px;
    width: 612px;
    height: 100%;
    margin-bottom: -6px;
    aspect-ratio: auto;
    overflow: auto;
}

.service-card-img-left .service-card-image {
    border-radius: 20px 0px 0px 20px;
    width: 612px;
    height: 100%;
    margin-bottom: -6px;
    aspect-ratio: auto;
    overflow: auto;
}

.servicecard-second-content {
    width: 50%;
    padding: 40px;
    min-height: 565px;
    padding-left: 50px;

}

.servicecard-icon-content {
    justify-content: flex-start;
    margin-top: 20px;
}

.servicecard-second-content h1 {
    color: var(--main-font);
    margin-top: 0%;
    font-weight: 800;
}

.servicecard-second-content p {
    color: var(--main-font);
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
}

.servicecard-icon {
    width: 10%;
    height: 100%;
    max-height: 50px;
    margin-right: 14px;
    margin-bottom: 10px;
    background-color: var(--white);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.servicecard-icon img {
    width: 100%;
    height: 100%;
}

.servicecard-check-full-width {
    width: 100% !important;
}

.servicecard-check {
    display: flex;
    width: 48%;
    margin-bottom: 20px;
    align-items: center;
    color: var(--heighlight-font);
}

.servicecard-check span {
    color: var(--main-font);
    margin-left: 10px;
}

.servicecard-check-content {
    margin-top: 25px;
}

.servicecard-btn {
    width: 100%;
    padding-top: 3%;
}

@media (max-width: 1325px) {
    .service-card-content {
        padding: 20px 0px;
    }

    .service-card-img-left .service-card-image {
        width: 100%;
    }

    .service-card-img .service-card-image {
        width: 100%;
    }
}

@media (min-width: 1325px) {
    .service-card-img-left .service-card-image {
        width: 100%;
    }

    .service-card-img .service-card-image {
        width: 100%;
    }
}

@media (max-width: 905px) {
    .service-card-img-left .service-card-image {
        width: 50%;
        border-radius: 20px;
    }

    .service-card-img .service-card-image {
        width: 50%;
        border-radius: 20px;
    }

    .servicecard-icon {
        min-height: 85px;
    }

    .service-card-img-left {
        margin-top: 25px;
        padding-bottom: 0px;
    }

    .service-card-img {
        margin-top: 25px;
        padding-bottom: 0px;
    }

    .service-card-content {
        flex-direction: column-reverse;
        margin-bottom: 20px !important;
        padding: 0px;
        padding-bottom: 0px;
    }

    .service-card-content-left {
        flex-direction: column;
        margin-bottom: 20px !important;
        padding: 0px;
        padding-bottom: 0px;
    }

    .service-card-img-left {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .service-card-img-left img {
        width: 50%;
        height: 100%;
        border-radius: 20px;
    }

    .service-card-img {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .service-card-img img {
        width: 50%;
        height: 100%;
        border-radius: 20px;
    }

    .servicecard-second-content {
        width: 100%;
        padding: 20px;
    }

    .servicecard-second-content h1 {
        text-align: center;
    }
}

@media (max-width: 670px) {
    .servicecard-check-content {
        flex-direction: column;
        align-items: start;
    }

    .servicecard-icon {
        width: 14%;
    }

    .servicecard-check {
        width: 100%;
    }
}

@media (max-width: 550px) {

    .servicecard-icon {
        width: 11%;
    }
}

@media (max-width: 490px) {
    .servicecard-second-content h1 {
        font-size: 24px;
    }

    .servicecard-icon {
        width: 20%;
    }

    .servicecard-second-content p {
        font-size: 14px;
    }

    .servicecard-btn {
        text-align: center;
    }
}

@media (max-width: 400px) {
    .servicecard-icon {
        width: 19%;
        min-height: 45px;
    }

    .servicecard-second-content {
        padding: 10px;
    }
}