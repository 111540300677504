.keycard-main {
    padding: 0px 5%;
    padding-bottom: 50px;
}

.keycard-icon {
    width: 40px;
    height: 40px;
    color: var(--heigh);
}

.keycard {
    border: 2px solid var(--heighlight-font);
    border-radius: 15px;
    width: 16%;
    padding: 15px;
    background-color: var(--bg-color);
}

.keycard h2 {
    margin: 0px;
    color: var(--heighlight-font);
    font-weight: 800;
    font-size: 22px;
}

.keycard h3 {
    margin: 0px;
    color: var(--main-font);
    font-weight: 500;
    margin-top: 18px;
}

@media screen and (min-width: 1600px) {
    .keycard-main {
        padding: 0px 16%;
        margin-bottom: 70px;
    }

}

@media screen and (max-width: 800px) {
    .keycard {
        width: 48%;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .keycard {
        width: 100%;
    }
}