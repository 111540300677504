.adcard-main {
    background-color: var(--heighlight-font);
    border-radius: 20px;
    padding: 5px 30px;
    color: var(--white);
    margin: 50px 0px;
}

.adcard-details {
    width: 70%;
    
}

.adcard-details p {
    font-weight: 700;
}

.adcard-details h1 {
    font-weight: 700;
    margin-top: 0%;
}

.adcard-details .adcard-buttom-text {
    font-weight: 500;
    margin-top: 0%;
}

.adcard-details h4 span {
    font-weight: 700;
    font-size: 23px;
}

/* second button */

.contect-button a {
    display: flex;
    align-items: center;
    display: block;
    font-weight: bold;
    min-width: 250px;
    height: 45px;
    line-height: 40px;
    font-size: 18px;
    text-decoration: none;
    color: var(--heighlight-font) !important;
    background-color: var(--white);
    border: 2px solid var(--white);
    text-align: center;
    position: relative;
    transition: all .35s;
    border-radius: 20px;
}

.contect-button a span {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 900px) {
    .adcard-main {
        flex-direction: column;
    }

    .adcard-details {
        width: 100%;
        padding-right: 0px;
    }

    .adcard-details p {
        font-size: 12px;
    }

    .adcard-details h1 {
        font-size: 26px;
    }

    .adcard-details h4 {
        font-size: 14px;
    }

    .adcard-details h4 span {
        font-size: 20px;
    }
}