.expertisecard-main {
    border: 1px solid var(--heighlight-font);
    background-color: var(--white);
    width: 32%;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
}

.expertisecard-main h2 {
    color: var(--main-font);
}

@media screen and (max-width: 1115px) {
    .expertisecard-main {
        width: 48%;
    }
}

@media screen and (max-width: 600px) {
    .expertisecard-main {
        width: 100%;
    }
}