.location {}

.location-part {
    width: 32%;
}

.location-title {
    display: flex;
    align-items: center;
}

.location-title video {
    width: 50px;
    height: 35px;
}

.location-title h1 {
    margin: 0px;
    color: var(--main-font);
    margin-left: 5px;
    font-weight: 700;
}

.location-part h3 {
    color: var(--main-font);
    margin: 0px;
    font-weight: 800;
    padding-top: 5px;
}

.location-text-city {
    color: var(--main-font);
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    font-weight: 800;
    padding-top: 5px;
}

.location-address {
    display: flex;
    align-items: top;
}

.sales-address {
    display: flex;
    align-items: center;
    padding-bottom: 13px;
}

.sales-address p {
    margin: 0;
    color: var(--secondary-color);
    padding: 0px 10px;
}

.location-address p {
    margin: 0;
    text-align: start;
    color: var(--black);
    padding: 0px 10px;
}

.sales-address p a {
    color: var(--black);
    text-decoration: none;
}

.loaction-icon {
    color: var(--heighlight-font) !important;
    width: 30px;
    height: 22px;
}

.sales h4 {
    color: var(--main-font);
    margin: 0px;
    padding-bottom: 15px;
    padding-top: 25px;
    font-weight: 800;
}

.sales-heading {
    color: var(--main-font);
    padding-bottom: 5px;
    padding-top: 25px;
    font-weight: 800;
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.sales-icon {
    color: var(--heighlight-font) !important;
    font-size: 19px;
}