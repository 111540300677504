.keybenefits-main {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: var(--bg-color);
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    width: 23%;
}
.keybenefits-top{
    margin-bottom: 80px;
}

.keybenefits-image-container {
    width: 60px;
    height: 60px;
    margin: 0 auto;
}
.Keybenefits-img {
    text-align: center;
}

.keybenefits-main .keybenefits-img1 {
   max-width: 100%;
   aspect-ratio: auto;
   max-height: 100%;
   overflow: auto;
}

@media screen and (max-width: 850px) {
    .keybenefits-main {
        width: 31%;
    }
}

@media screen and (max-width: 635px) {
    .keybenefits-main {
        width: 48%;
    }
}

@media screen and (max-width: 566px) {
    .keybenefits-main {
        width: 100%;
    }
}