/* Basic card styles */
.card {
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    text-align: center;
    padding: 20px 30px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;
}

.card2 {
    background-color: var(--bg-color);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    text-align: center;
    padding: 20px 30px;
    margin-bottom: 23px;
    min-height: 470px;
    position: relative;
    border-radius: 10px;
}

/* Image styles */
.card-img-div {

    max-height: 210px;

}

.card-image {
    aspect-ratio: auto;
    max-height: 210px;
    border-radius: 15px;
    max-width: 100%;
    /* height: auto; */
}

/* Title styles */
.card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
}

/* Description styles */
.card-description {
    text-align: center;
    text-overflow: ellipsis;
    color: var(--main-font);
    font-weight: 500;
    margin-bottom: 0%;
}

.card-button {
    position: absolute;
    left: 27%;
    bottom: -4%;
}

@media (max-width: 768px) {
    main {
        padding: 10px;
    }
}

@media (max-width: 800px) {
    .card-button {
        left: 38%;
    }
}

@media (max-width: 610px) {
    .card-button {
        left: 33%;
    }
}

@media (max-width: 550px) {
    .card-button {
        left: 30%;
    }
}

@media (max-width: 410px) {
    .card-button {
        left: 26%;
    }
}

@media (max-width: 330px) {
    .card-button {
        left: 21%;
    }
}

@media (max-width: 300px) {
    .card-button {
        left: 17%;
    }
}