:root {
  --black: #000;
  --white: #fff;
  --heighlight-font: #0d6efd;
  --main-font: #142149;
  --secondary-color: #5e7290;
  --bg-color: #f2f5fe;
  --blog-font: #4c4d56;
  --blog-font-heighlight: #142149;
}
@font-face {
  font-family: "Nunito";
  src: url("https://cdn.technolee.com/fonts/Nunito-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

* {
  font-family: 'Nunito', sans-serif;
}

p {
  text-align: justify;
}

body {
  margin: 0;
}

.container {
  padding: 0px 5%;
  width: 100%;

}

.text-highlight {
  color: var(--heighlight-font);
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.flex-space-between {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.page-content {
  margin-top: 80px !important;
}


/* Loader css  */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid var(--main-font);
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  transform: rotate3d(90, 90, 0, 180deg);
  border-color: var(--heighlight-font);

}


@keyframes rotate {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
  }

  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
  }
}


/* screen opecity */

.screen-animation {
  animation: fadeInAnimation ease 1.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* recaptcha css */

.recaptcha{
  margin: 0%;
    color: #ff0000;
    font-weight: 700;
    font-size: 14px;
}

/* Home page css */
.homeservice-card {
  width: 31%;
}

.homeservice-main {
  align-items: flex-start;
  margin-top: 30px;
}
.home-core-service{
  padding: 0px 5%;
  background-color: var(--bg-color);
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 80px;

}
.home-work-process{
  padding: 15px 5%;
  background-color: var(--bg-color);
}
.home-tab{
  margin-top: 80px;
}

/* About page css */

.business-main {
  background-color: var(--bg-color);
  padding-top: 1px;
  margin-top: 40px;
}

.ourcard {
  padding: 0px 5%;
}

.core-card {
  width: 32%;
}

/* technology page css */

.tech-expertise-main {
  background-color: var(--bg-color);
  padding: 1px 0px;
  padding-bottom: 60px;
}

.faqs-main {
  margin-bottom: 50px;
}

/* BlogDetails page css */

.blog-details-content P {
  color: var(--blog-font);
  font-size: 18px;
  font-weight: 400;
}

.blog-details-content h1 {
  color: var(--main-font);
  margin-bottom: 0px;
  font-weight: 800;
}

.blog-list-ul {
  color: var(--blog-font);
}

.blog-list-ul li span {
  color: var(--blog-font-heighlight);
  font-weight: 800;
}

.blogdetails-fig {
  width: 100%;
  height: 300px;
}

article {
  --img-scale: 1.001;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.blogdetails-img-name {
  position: absolute;
  right: 4%;
  bottom: 6%;

}

.blogdetails-img-name p {
  background-color: var(--heighlight-font);
  color: var(--white);
  margin: 0px;
  font-size: 13px;
  padding: 4px 5px;
  font-weight: 400;
  border-radius: 5px
}

/* Service page css */

.service-form {

  padding: 10px 0px;
}


/*  card css */
.card-cantent {
  width: 30%;
}

/* contect page css */

.contectus-card {
  background-color: var(--white);
}

.visitus {
  padding-top: 5px;
  background-color: var(--bg-color);
  margin-top: 20px;
}

.visit-content {
  align-items: flex-start !important;
}

.testimonials-contectus {
  margin: 80px 0px;
}

/* google-map css */

.address-label {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 1;
}

.map-container {
  width: 100%;
  height: 360px;
  position: relative;
  margin-bottom: 30px;
}


/* scroll style */

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.6em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00);
  /* -webkit-box-she: inset 0 0 6px rgba(0,0,0,0.00); */
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--heighlight-font);
  outline: 1px solid var(--heighlight-font);
}


/* Home page Media qurry */

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }

  .visit-content {
    padding: 0px 16% !important;
  }
}

@media screen and (max-width: 1200px) {
  .homeservice-card {
    width: 47%;
  }
}

@media screen and (max-width: 900px) {
  .core-card {
    width: 47%;
  }
}

@media screen and (max-width: 800px) {
  .homeservice-card {
    width: 100%;
  }

  .visit-content {
    flex-direction: column;

  }

  .visit-data {
    width: 50% !important;
  }

  .map-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 650px) {

  .core-card {
    width: 100%;
  }
}

@media screen and (max-width: 470px) {

  .visit-data {
    width: 100% !important;
  }

  .screen-animation {
    animation: fadeInAnimation ease 50ms !important;

  }
}