.technologyHireCard-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    position: relative;
    width: 100%;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */

}

.technologyHireCard-step {
    text-align: center;
    width: 220px;
    min-height: 340px;
    position: relative;
}

.technologyHireCard-icon-container {
    background-color: #0066cc;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    z-index: 2;
}

.technologyHireCard-icon {
    color: white;
}

.technologyHireCard-line-container {
    position: absolute;
    top: 15%;
    left: 110px;
    width: calc(100% - 220px);
    height: 0;
    z-index: -1;
}

.technologyHireCard-dotted-line {
    flex-grow: 1;
    border-top: 1px dashed #0066cc;
}

.technologyHireCard-arrow-main {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    top: -33px;
}

.technologyHireCard-arrow1,
.technologyHireCard-arrow2,
.technologyHireCard-arrow3 {
    font-size: 45px;
    color: #0066cc;
}

.technologyHireCard-step h3 {
    font-size: 18px;
    color: #003366;
    margin-top: 20px;
    z-index: 3;
    position: relative;
}

.technologyHireCard-step p {
    font-size: 14px;
    color: #666666;
    line-height: 1.5;
    margin-top: 10px;
    z-index: 3;
    position: relative;
}

.technologyHireCard-step:last-child .technologyHireCard-line-container {
    display: none;
}
@media (max-width: 1050px) {
    .technologyHireCard-arrow1,
    .technologyHireCard-arrow2,
    .technologyHireCard-arrow3 {
        display: none;
    }
    .technologyHireCard-dotted-line {
      display: none;
    }
    
}
@media (max-width: 450px) {
    .technologyHireCard-container {
        justify-content: center;
    }
    .technologyHireCard-step {
        width: 100%;
        min-height: 250px;
    }
}
@media (max-width: 540px) {
    .technologyHireCard-step {
        width: 100%;
        min-height: 250px;
    }
}
@media (max-width: 350px) {
   
    .technologyHireCard-step {
        width: 100%;
        min-height: 270px;
    }
}
