.contect-main {
    width: 100%;
    background-color: var(--white);
    padding: 20px 25px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin: auto;
    border-radius: 20px;
}

.contect-main h1 {
    color: var(--heighlight-font);
}

.form-group {
    margin-bottom: 30px;
    width: 48%;
}

.form-group p,
.form-group-msg p {
    margin: 0%;
    color: #ff0000;
    font-weight: 700;
    font-size: 14px;
}

.form-group-msg {
    margin-bottom: 10px;
    width: 100%;
}

label {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--main-font);
    margin-bottom: 5px;
}

textarea:focus,
.form-group input:focus {
    outline: none;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group-msg textarea {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid var(--bg-color);
    color: var(--main-font);
    font-weight: 700;
    font-size: 15px;
    background-color: var(--bg-color);
    border-radius: 5px;
}

textarea {
    height: 100px;
}

/* submit form  */

.submit-btn {
    width: fit-content;
    display: flex;
    text-align: center;

    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    background-color: var(--heighlight-font);
    border-radius: 40px;

}

.submit-btn:hover {
    background-color: transparent !important;
}

.submit-icon {
    margin-left: 5px;
    font-size: 15px;
}

.submit-btn button {
    display: block;
    border: none;
    min-width: 180px;
    position: relative;
    text-decoration: none;
    color: var(--white);
    cursor: pointer;
    border-radius: 40px;
    background: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    padding: 13px 15px;
    width: 160px;
    overflow: hidden;
}

.submit-btn button span {
    z-index: 5;
    position: relative;
    transition: color 500ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-btn button:hover span {
    color: white;
}

.submit-btn button::after,
.submit-btn button::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--main-font);
    z-index: -1;
}

.submit-btn button::before {
    /*   background: blue; */
    transform: translateX(-100%);
    z-index: 1;
}

.submit-btn button:hover:before {
    transform: translateX(0);
    transition: transform 500ms ease;
}

.submit-btn button::after {
    z-index: 0;
    transform: translateX(100%);
    transition: none;
    transition: transform 500ms ease;
}

.submit-btn button:hover:after {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms 500ms ease;
}


@media screen and (max-width: 1033px) {
    .aboutcard-img {
        width: 80% !important;
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .aboutcard-content h2 {

        width: 100% !important;

    }

    .aboutcard-img {
        width: 60% !important;

    }

    .contect-fprm-main {
        width: 100% !important;

    }

    .form-group {
        margin-bottom: 10px;
        width: 100%;
    }
}

.PhoneInput {
    justify-content: center !important;
    background-color: var(--bg-color);
}

.PhoneInputCountry {
    border-right: 2px solid var(--white);
    margin-left: 10px !important;
}

.PhoneInputCountrySelectArrow {
    margin-right: 8px !important;
}