.hiringcard-main {
    padding: 10px 40px;
    position: relative;
    align-items: flex-start !important;
    justify-content: flex-start;
    gap: 3.7px;

}

.hiringcard-content {
    width: 14%;
    text-align: center;
    height: 100%;
    margin-bottom: 25px;
}

.hiringcard-content h2 {
    margin-top: 0px;
    font-size: 20px;
    padding: 0px 35px;
    color: var(--main-font);
    font-weight: 800;
    -webkit-font-smoothing: antialiased;
}

.hiringcard-icon {
    width: 40%;
    color: var(--heighlight-font);
    height: 100%;
    margin: 0 auto;
}
.hiringcard-image {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}
.hiringcard-line1 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    left: 14%;
    top: 25%;

}

.hiringcard-line2 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    left: 28%;
    top: 25%;
}

.hiringcard-line3 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    left: 41%;
    top: 25%;
}

.hiringcard-line4 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    right: 41%;
    top: 25%;
}

.hiringcard-right-corner {
    position: absolute;
    border-left: none !important;
    border: 4px dashed var(--heighlight-font);
    width: 3%;
    height: 53%;
    right: 4%;
    top: 12%;
    border-radius: 0px 150px 150px 0px;
}

.hiringcard-line5 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    right: 27%;
    top: 25%;
}

.hiringcard-line6 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 5%;
    right: 14%;
    top: 25%;
}

/* .hiringcard-line7 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    left: 56%;
    top: 63%;
}

.hiringcard-line8 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    right: 18%;
    top: 63%;
}

.hiringcard-line9 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    left: 18%;
    top: 72%;
}

.hiringcard-line10 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    left: 37%;
    top: 72%;
}

.hiringcard-line11 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    left: 56%;
    top: 72%;
}

.hiringcard-line12 {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 7%;
    right: 18%;
    top: 72%;
}

.hiringcard-left-corner {
    position: absolute;
    border-right: none !important;
    border: 4px dashed var(--heighlight-font);
    width: 3%;
    height: 33%;
    left: 4%;
    top: 40%;
    border-radius: 150px 0px 0px 150px;
} */

@media screen and (max-width: 1320px) {
    .hiringcard-content h2 {
        padding: 0px 10px;
    }

    .hiringcard-content {
        width: 13%;
    }

    .hiringcard-line1 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        left: 13.6%;
        top: 25%;
    }

    .hiringcard-line2 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        left: 26.5%;
        top: 25%;
    }

    .hiringcard-line3 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        left: 38.5%;
        top: 25%;
    }

    .hiringcard-line4 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        right: 44.5%;
        top: 25%;
    }

    /* .hiringcard-right-corner {
        display: none;
    } */

    .hiringcard-line5 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        right: 32.5%;
        top: 25%;
    }

    .hiringcard-line6 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 4%;
        right: 20%;
        top: 25%;
    }

    /* .hiringcard-line7 {
        display: none;
    }

    .hiringcard-line8 {
        display: none;
    }

    .hiringcard-line9 {
        display: none;
    }

    .hiringcard-line10 {
        display: none;
    }

    .hiringcard-line11 {
        display: none;
    }

    .hiringcard-line12 {
        display: none;
    }

    .hiringcard-left-corner {
        display: none;
    } */
}


@media screen and (max-width: 1040px) {
    .hiringcard-content h2 {
        font-size: 15px;
    }
}

@media screen and (max-width: 900px) {
    .hiringcard-main {
        gap: 0px;
    }

    .hiringcard-content h2 {
        padding: 0px 10px;
    }

    .hiringcard-content {
        width: 33.33%;
    }

    .hiringcard-line1 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 10%;
        left: 30%;
        top: 11%;
    }

    .hiringcard-line2 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 10%;
        left: 60%;
        top: 11%;
    }

    .hiringcard-line3 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 10%;
        left: 30%;
        top: 42%;
    }

    .hiringcard-line4 {
        position: absolute;
        border: 2px dashed var(--heighlight-font);
        width: 10%;
        right: 30%;
        top: 42%;
    }

    /* .hiringcard-right-corner {
        display: none;
    } */

    .hiringcard-line5 {
        display: none;
    }

    .hiringcard-line6 {
        display: none;
    }

    /* .hiringcard-line7 {
        display: none;
    }

    .hiringcard-line8 {
        display: none;
    }

    .hiringcard-line9 {
        display: none;
    }

    .hiringcard-line10 {
        display: none;
    }

    .hiringcard-line11 {
        display: none;
    }

    .hiringcard-line12 {
        display: none;
    }

    .hiringcard-left-corner {
        display: none;
    } */
}


@media screen and (max-width: 550px) {
    .hiringcard-content {
        width: 50%;
    }

    .hiringcard-line1 {
        display: none;
    }

    .hiringcard-line2 {
        display: none;
    }

    .hiringcard-line3 {
        display: none;
    }

    .hiringcard-line4 {
        display: none;
    }

    .hiringcard-line5 {
        display: none;
    }

    .hiringcard-line6 {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .hiringcard-content {
        width: 100%;
    }
}