.business-main-card {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    padding: 40px;
    border-radius: 10px;
   margin-bottom: 40px;
    background-color: var(--white);
}

.business-head {
    text-align: center;
}

.business-head h2 {
    color: var(--main-font);
    font-weight: 800;
    margin-top: 5px;
}

.head-icon {
    width: 60px;
    margin: 0 auto;
    height: 60px;
    color: var(--heighlight-font)

}
.head-icon-image {
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}
.business-card {
    text-align: center;
    width: 49%;
    margin-bottom: 22px;
    min-height: 335px;

    border-radius: 10px;
    padding: 17px 50px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.business-card h2 {
    color: var(--main-font);
    font-weight: 800;
    margin-top: 10px;
}

.business-card p {
    color: var(--secondary-color);
    font-weight: 500;
    line-height: 1.4em;
}

.business-card2 {
    text-align: center;
    width: 49%;
    min-height: 335px;
    background-color: var(--main-font);
    border-radius: 10px;

    padding: 17px 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.business-card2 h2 {
    color: var(--white);
    font-weight: 800;
    margin-top: 10px;
}

.business-card2 p {
    color: var(--white);
    font-weight: 500;
    line-height: 1.4em;
}

.business-details p {
    color: var(--secondary-color);
    font-weight: 500;
}

.business-details p span {
    color: var(--main-font);
    font-weight: 700;
}


@media screen and (min-width: 1600px) {

    .ourcard {
        padding: 0px 16%;
    }

}

@media screen and (max-width: 650px) {

    .business-card {
        width: 100%;
    }

    .business-card2 {
        width: 100%;
        margin-bottom: 50px;
    }
}