.work-card-main {
    position: relative;
    align-items: flex-start !important;
    margin-top: 5%;
}

.workcard-block {
    width: 25%;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.workcard-block h3 {

    font-size: 22px;
    font-weight: 800;
    color: var(--main-font);
    margin-bottom: 8px;
}

.workcard-block p {
    font-size: 16px;
    font-weight: 500;
    padding: 0px 20px;
    line-height: 21px;
    text-overflow: ellipsis;
    color: var(--black);
    margin-top: 0px;
}

.workcard-content {
    display: flex;
    justify-content: center;
}

.workcard-content-inner {
    width: 45%;
    background-color: var(--bg-color);
    padding: 10px;
    border: 3px dashed var(--heighlight-font);
    border-radius: 130px
}

.workcard-content-inner div {
    width: 100%;
    padding: 15px 15px;
    background-color: var(--white);
    border-radius: 65px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.workcard-icon {
    background-color: var(--white);
    /* width: 84%; */
    border-radius: -5px;
    /* height: 100%; */
    color: var(--heighlight-font);
    max-width: 95%;
    max-height: 100%;
    aspect-ratio: auto;
    overflow: auto;
}

.workcard-line {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 32%;
    top: 65px;
}

.workcard-line-right {
    position: absolute;
    border: 2px dashed var(--heighlight-font);
    width: 32%;
    top: 65px;
    right: 0%;
}

.workcard-right-corner {
    position: absolute;
    border-left: none !important;
    border: 4px dashed var(--heighlight-font);
    width: 7%;
    height: 51%;
    right: 0%;
    top: 7.7%;
    border-radius: 0px 150px 150px 0px;
}

@media screen and (max-width: 1150px) {
    .workcard-line {
        top: 60px;
    }

    .workcard-line-right {
        top: 60px;
    }

}

@media screen and (max-width: 1100px) {
    .workcard-right-corner {
        height: 48%;
        width: 8%;
        top: 6.2%;
        right: -1%;

    }
}

@media screen and (max-width: 950px) {
    .workcard-line {
        top: 60px;
    }

    .workcard-line-right {
        top: 60px;
    }

    .workcard-block p {
        padding: 0px 10px;
    }
}

@media screen and (max-width: 850px) {
    .workcard-right-corner {
        display: none;
    }

    .workcard-line {
        display: none;
    }

    .workcard-line-right {
        display: none;
    }

    .work-card-main {
        display: block;
        justify-content: center !important;
    }

    .workcard-block {
        width: 100%;
    }

    .workcard-content-inner {
        width: 25%;
    }

    .workcard-block p {
        padding: 0px 25%;
    }

    .workcard-block {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 500px) {
    .workcard-content-inner {
        width: 30%;
    }

    .workcard-block p {
        padding: 0px 10%;
    }
}